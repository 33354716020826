import * as logger from "common/logger";

export const getExposureTrendData = (exposures, data) => {
  const selectedExposures = exposures.map((x) => x.key);
  const exposureNames = Object.fromEntries(
    exposures.map((x) => [x.key, x.name])
  );
  const exposureData = {};
  const years = Object.keys(data).sort((a, b) => a - b);

  years.forEach((year) => {
    const yearData = data[year];
    Object.entries(yearData).forEach(([key, val]) => {
      if (selectedExposures.includes(key) && val != null) {
        if (exposureData[key] == null) {
          const name = exposureNames[key];
          exposureData[key] = { key: key, name: name, values: [] };
        }
        exposureData[key].values.push(val);
      }
    });
  });

  return Object.values(exposureData).map((x) => x);
};

export const getExposureChartData = (data) =>
  Object.entries(data).map(([k, v]) => {
    return { year: k, ...v };
  });

export const tryParse1dArray = (text) => {
  if (!text || typeof text !== "string") {
    return null;
  }
  text = text.trim();
  const hasTabs = text.includes("\t");
  const hasNewlines = text.includes("\n");
  if (hasTabs || hasNewlines) {
    if (hasTabs && hasNewlines) {
      logger.error(`Pasted 2d array. Ignoring.\n${text}`);
      return null;
    }
    return text.split(hasTabs ? "\t" : "\n").map((item) => item.trim());
  } else {
    return null;
  }
};
