import * as dates from "common/dates";
import * as config from "config";
import * as claims from "domain/claims";
import * as attatchmentActionTypes from "store/actions/input/attachments/attachmentsActionTypes";
import * as actionTypes from "store/actions/input/claims/claimsActionTypes";
import * as actions from "store/actions/input/claims/claimsActions";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const INITIAL_STATE = {
  files: [],
  activeFile: null,
  asAtDate: null,
  recommendedAsAtDate: null,
  manualClaims: [],
  threshold: 10000,
  inflationModel: "UNEXPECTEDLY_THE_INFLATION_MODEL_HAS_NOT_BEEN_SET",
};

const getManualClaimsKey = (actionType) => {
  switch (actionType) {
    case actionTypes.MANUAL_UPDATE_LOSS_YEAR:
      return "lossYear";
    case actionTypes.MANUAL_UPDATE_COVERAGE:
      return "coverage";
    case actionTypes.MANUAL_UPDATE_LOSS:
      return "lossAmount";
    case actionTypes.MANUAL_UPDATE_DESCRIPTION:
      return "description";
    case actionTypes.MANUAL_UPDATE_CLAIMANT:
      return "claimant";
    case actionTypes.MANUAL_UPDATE_OPEN_CLOSED:
      return "oc";
    case actionTypes.MANUAL_UPDATE_DATE_CLOSED:
      return "dateClosed";
    default:
      throw new Error(`${actionType} is an Unsupported Claim Detail`);
  }
};

const updateFiles = (files, key, update) =>
  (files || []).map((file) =>
    file.claimsKey === key ? { ...file, ...update } : file
  );

const claimsReducer = (state, action) => {
  if (state === undefined) {
    state = INITIAL_STATE;
  }

  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INITIAL_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INITIAL_STATE,
        ...action?.payload?.data?.state?.input?.claims,
      };
    case actionTypes.CLEAR_CLAIMS_FILTER:
      return { ...state, filter: {} };
    case actionTypes.UPDATE_CLAIMS_FILTER:
      return {
        ...state,
        filter: {
          ...(state.filter || {}),
          [action.payload.filter]: action.payload.value,
        },
      };
    case actionTypes.UPDATE_CLAIM_THRESHOLD:
      return {
        ...state,
        threshold: action.payload,
      };
    case actionTypes.CLAIMS_FILE_DROPPED:
      return {
        ...state,
        activeFile: {
          fileIsUploading: true,
          sheetsDialogIsOpen: true,
          file: action.payload,
          step: 0,
        },
        fileUploadCancelled: false,
      };
    case attatchmentActionTypes.COPYING_ATTACHMENT_TO_LOSS_FILE:
      return {
        ...state,
        activeFile: {
          fileIsUploading: true,
          sheetsDialogIsOpen: true,
          attachment: action.payload,
          file: action.payload.filename,
          step: 0,
        },
        fileUploadCancelled: false,
      };
    case actionTypes.EDIT_CLAIMS_FILE:
      let fileToBeEdited = state.files.find(
        (x) => x.uploadedFilename === action.payload
      );
      fileToBeEdited = fileToBeEdited
        ? {
            ...fileToBeEdited,
            sheets: undefined,
            step: 0,
          }
        : null;
      return {
        ...state,
        activeFile: fileToBeEdited,
        fileUploadCancelled: false,
      };
    case actionTypes.SET_V2_CLAIMS_KEY:
      return {
        ...state,
        files: [
          ...state.files,
          {
            sheets: action.payload.tablesList,
            activeSheet: action.payload.tablesList.indexOf(
              action.payload.tableName
            ),
            sheetName: action.payload.tableName,
            checked: true,
            prettyName: action.payload.uploadedFilename,
            originalDownloadHasError: false,
            originalDownloading: false,
            ...action.payload,
            isFilesFile: true,
            fileBackendV2: true,
          },
        ],
      };
    case actionTypes.CLAIMS_FILE_UPLOADED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          fileIsUploading: false,
          uploadedFilename: action.payload.filename,
          claimsKey: action.payload.claimsKey,
        },
      };
    case actionTypes.FILE_CLAIMS_FILE_UPLOADED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          fileIsUploading: false,
          step: 0,
          file: {},
          sheetsDialogIsOpen: true,
          uploadedFilename: action.payload.filename,
          claimsKey: action.payload.id,
          isFilesFile: true,
          fileId: action.payload.id,
        },
        fileUploadCancelled: false,
      };
    case actionTypes.CLAIMS_FILE_UPLOAD_FAILED:
      return {
        ...state,
        activeFile: undefined,
      };
    case actionTypes.FILE_SHEET_NAMES_RETURNED:
      if (state.activeFile.fileId !== action.payload.id) {
        return state;
      }
      const sheetNames = action.payload.sheets.map((sheet) => sheet.name);
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          activeSheet: 0,
          sheets: sheetNames,
          columns: undefined,
          columnMapping: {},
        },
      };
    case actionTypes.SHEET_NAMES_RETURNED:
      if (state.activeFile.uploadedFilename !== action.payload.filename) {
        return state;
      }
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          activeSheet: 0,
          sheets: action.payload.sheets.map((sheet) => sheet.name),
          columns: undefined,
          columnMapping: {},
        },
      };
    case actionTypes.CLAIMS_DIALOG_NEXT_STEP:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          step: state.activeFile.step + 1,
        },
      };
    case actionTypes.CLAIMS_DIALOG_PREV_STEP:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          step: state.activeFile.step - 1,
        },
      };
    case actionTypes.CLAIMS_DIALOG_CLOSED:
      return {
        ...state,
        activeFile: null,
        fileUploadCancelled: true,
      };
    case actions.transformClaimsFile.started.toString():
      if (state.activeFile) {
        return {
          ...state,
          activeFile: {
            ...state.activeFile,
            transformStatus: "RUNNING",
            transformError: null,
          },
        };
      } else {
        return state;
      }
    case actions.transformClaimsFile.succeeded.toString():
      const recommendedAsAtDate = dates.getLastDateStringOfMonth(
        action.payload.lastLossDate
      );
      const latestAsAtDate =
        state.recommendedAsAtDate == null ||
        recommendedAsAtDate > state.recommendedAsAtDate
          ? recommendedAsAtDate
          : state.recommendedAsAtDate;
      const {
        newFileProcessed,
        fileIsProcessing,
        transformStatus,
        ...residue
      } = state.activeFile || {};
      const previousClaimsKey = residue.claimsKey ?? null;
      const processedFile = {
        ...residue,
        ...action.payload,
        checked: true,
      };
      const files = (state.files || []).some(
        (x) => x.claimsKey === previousClaimsKey
      )
        ? (state.files || []).map((x) =>
            x.claimsKey === previousClaimsKey ? { ...x, ...processedFile } : x
          )
        : [...(state.files || []), processedFile];
      return {
        ...state,
        recommendedAsAtDate: latestAsAtDate,
        activeFile: {
          ...state.activeFile,
          claimsKey: action.payload.claimsKey,
          transformStatus: "SUCCEEDED",
          transformError: null,
        },
        files,
      };
    case actions.transformClaimsFile.failed.toString():
      if (state.activeFile) {
        return {
          ...state,
          activeFile: {
            ...state.activeFile,
            transformStatus: "FAILED",
            transformError: action.payload.errorMessage,
          },
        };
      } else {
        return state;
      }
    case actionTypes.COLUMN_MAPPING_UPDATED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columnMapping: {
            ...state.activeFile.columnMapping,
            [action.payload.target]:
              state.activeFile.columnMapping[action.payload.target] ===
                undefined &&
              state.activeFile.columnMappingSuggestions?.[
                action.payload.target
              ] === action.payload.column
                ? null
                : action.payload.selected
                ? action.payload.column
                : null,
          },
        },
      };
    case actionTypes.UPDATE_CLAIMS_AS_AT_DATE:
      return {
        ...state,
        asAtDate: action.payload,
      };
    case actionTypes.MAPPING_SUGGESTIONS_RETURNED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          [`${action.payload.hintType}Suggestions`]: action.payload.suggestions,
        },
      };
    case actionTypes.SHEET_SELECTED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          activeSheet: action.payload,
          topLeft: { row: 0, column: 0 },
          columns: undefined,
        },
      };
    case actionTypes.SHEET_TOP_LEFT_SELECTED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          topLeft: action.payload,
          columns: undefined,
        },
      };
    case actionTypes.COLUMN_NAMES_REQUESTED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columns: null,
          columnsState: {
            status: "running",
          },
        },
      };
    case actionTypes.COLUMN_NAMES_RETURNED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columns: action.payload,
          columnsState: {
            status: "succeeded",
          },
        },
      };
    case actionTypes.COLUMN_NAMES_FAILED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columns: null,
          columnsState: {
            status: "failed",
          },
        },
      };
    case actions.mapping.columns[
      config.EXCLUDE_KEY
    ].candidatesWaiting.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          excluded: null,
          excludedMapping: {},
        },
      };
    case actions.mapping.columns[
      config.LOSS_TYPE_KEY
    ].candidatesWaiting.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          lossTypes: null,
          lossTypeMapping: {},
        },
      };
    case actions.mapping.columns[
      config.OPEN_CLOSED
    ].candidatesWaiting.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          openClosed: null,
          openClosedMapping: {},
        },
      };
    case actions.mapping.columns[
      config.EXCLUDE_KEY
    ].candidatesInvalidated.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          excluded: undefined,
          excludedMapping: undefined,
          excludedMappingSuggestions: undefined,
        },
      };
    case actions.mapping.columns[
      config.LOSS_TYPE_KEY
    ].candidatesInvalidated.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          lossTypes: undefined,
          lossTypeMapping: undefined,
          lossTypeMappingSuggestions: undefined,
        },
      };
    case actions.mapping.columns[
      config.OPEN_CLOSED
    ].candidatesInvalidated.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          openClosed: undefined,
          openClosedMapping: undefined,
          openClosedMappingSuggestions: undefined,
        },
      };
    case actionTypes.EXCLUDED_MAPPING_UPDATED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          excludedMapping: {
            ...state.activeFile.excludedMapping,
            [action.payload.source]:
              state.activeFile.excludedMapping[action.payload.source] ===
                undefined &&
              state.activeFile.excludedMappingSuggestions?.[
                action.payload.source
              ] === action.payload.target
                ? null
                : action.payload.selected
                ? action.payload.target
                : null,
          },
        },
      };
    case actionTypes.LOSS_TYPE_MAPPING_UPDATED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          lossTypeMapping: {
            ...state.activeFile.lossTypeMapping,
            [action.payload.source]: action.payload.selected
              ? action.payload.target
              : null,
          },
        },
      };
    case actionTypes.OPEN_CLOSED_MAPPING_UPDATED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          openClosedMapping: {
            ...state.activeFile.openClosedMapping,
            [action.payload.source]:
              state.activeFile.openClosedMapping[action.payload.source] ===
                undefined &&
              state.activeFile.openClosedMappingSuggestions?.[
                action.payload.source
              ] === action.payload.target
                ? null
                : action.payload.selected
                ? action.payload.target
                : null,
          },
        },
      };
    case actionTypes.SET_CLAIMS_MAPPING:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          appliedExcess: action.payload,
        },
      };
    case actions.mapping.columns[
      config.EXCLUDE_KEY
    ].candidatesReturned.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          excluded: action.payload.candidates.map(
            (candidate) => candidate.name
          ),
          excludedMeta: action.payload.candidates,
        },
      };
    case actions.mapping.columns[
      config.LOSS_TYPE_KEY
    ].candidatesReturned.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          lossTypes: action.payload.candidates.map(
            (candidate) => candidate.name
          ),
          lossTypesTotal: action.payload.totalCount,
          lossTypesMeta: action.payload.candidates,
        },
      };
    case actions.mapping.columns[
      config.OPEN_CLOSED
    ].candidatesReturned.toString():
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          openClosed: action.payload.candidates.map(
            (candidate) => candidate.name
          ),
          openClosedTotal: action.payload.totalCount,
          openClosedMeta: action.payload.candidates,
        },
      };
    case actionTypes.CLAIMS_FILE_MAPPING_FAILED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          error: action.payload.error,
        },
      };
    case actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_STARTED:
      return {
        ...state,
        files: updateFiles(state.files, action.payload, {
          originalDownloading: true,
        }),
      };
    case actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_RETURNED:
      return {
        ...state,
        files: updateFiles(state.files, action.payload, {
          originalDownloadHasError: false,
          originalDownloading: false,
        }),
      };
    case actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_FAILED:
      return {
        ...state,
        files: updateFiles(state.files, action.payload, {
          originalDownloading: false,
          originalDownloadHasError: true,
        }),
      };
    case actionTypes.CLAIMS_FILE_CHECKED:
      return {
        ...state,
        files: updateFiles(state.files, action.payload.key, {
          checked: action.payload.checked,
        }),
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INITIAL_STATE,
        note:
          staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.input?.claims?.note ?? null,
        inflationModel:
          staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.input?.claims?.inflationModel ?? INITIAL_STATE.inflationModel,
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      const renewedNote = ({ renewalKey, priorValue, key = "note" }) => {
        const renewalConfig = staticDataSelectors.selectConfig("renewal")(
          action?.payload?.priorState
        );
        const renewalAction = renewalConfig?.byKey?.[renewalKey]?.action;

        if (!priorValue) {
          return {};
        } else if (renewalAction === "keep") {
          return { [key]: priorValue };
        } else if (renewalAction === "keep_with_renewal_message") {
          return {
            [key]: `**Carried forward from the prior submission:**\n\n${priorValue}`,
          };
        } else {
          return {};
        }
      };

      return {
        ...INITIAL_STATE,
        inflationModel:
          staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.input?.claims?.inflationModel ?? INITIAL_STATE.inflationModel,
        ...renewedNote({
          renewalKey: "input.claims.note",
          priorValue: state.note,
        }),
      };
    case actionTypes.UPDATE_CLAIMS_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case actionTypes.TOGGLE_CLAIM_EXCLUSION:
      const toggleClaimExclusion = (list) => {
        if (action.payload.excluded) {
          if (list.includes(action.payload.value)) {
            return list;
          }
          return [...list, action.payload.index];
        }
        return list.filter((x) => x !== action.payload.index);
      };
      return {
        ...state,
        exclusions: {
          ...state.exclusions,
          [action.payload.key]: toggleClaimExclusion(
            state.exclusions?.[action.payload.key] || []
          ),
        },
      };
    case actionTypes.OVERIDE_CLAIM_VALUE:
      const updateOverride = (overrides) => {
        if (action.payload.value != null) {
          return { ...overrides, [action.payload.index]: action.payload.value };
        }
        const { [action.payload.index]: value, ...without } = overrides;
        return without;
      };
      return {
        ...state,
        overrides: {
          ...state.overrides,
          [action.payload.key]: updateOverride(
            state.overrides?.[action.payload.key] || {}
          ),
        },
      };
    case actionTypes.SET_CLAIM_OPEN_CLOSED_OVERRIDE:
      return {
        ...state,
        openClosedOverrides: {
          ...state.openClosedOverrides,
          [action.payload.key]: {
            ...state.openClosedOverrides?.[action.payload.key],
            [action.payload.index]: action.payload.value,
          },
        },
      };
    case actionTypes.REMOVE_CLAIM_OPEN_CLOSED_OVERRIDE:
      return {
        ...state,
        openClosedOverrides: {
          ...state.openClosedOverrides,
          [action.payload.key]: Object.fromEntries(
            Object.entries(
              state.openClosedOverrides?.[action.payload.key] ?? {}
            ).filter(
              ([index]) => String(index) !== String(action.payload.index)
            )
          ),
        },
      };
    case actionTypes.MANUAL_CREATE_MERGE_ENTRY:
      const curClaims = state.manualClaims || [];
      action.payload.index = curClaims.length;
      const newState = {
        ...state,
        manualClaims: [...curClaims, action.payload],
      };
      return newState;

    case actionTypes.MANUAL_UPDATE_LOSS_YEAR:
    case actionTypes.MANUAL_UPDATE_OPEN_CLOSED:
    case actionTypes.MANUAL_UPDATE_DATE_CLOSED:
    case actionTypes.MANUAL_UPDATE_CLAIMANT:
    case actionTypes.MANUAL_UPDATE_DESCRIPTION:
    case actionTypes.MANUAL_UPDATE_LOSS:
    case actionTypes.MANUAL_UPDATE_COVERAGE:
      const oldManualClaims = state.manualClaims || [];
      const key = getManualClaimsKey(action.type);
      return {
        ...state,
        manualClaims: [
          ...oldManualClaims.slice(0, action.payload.index),
          {
            ...(oldManualClaims[action.payload.index] ??
              claims.DEFAULT_MANUAL_CLAIMS),
            [key]: action.payload[key],
          },
          ...oldManualClaims.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.DELETE_MANUAL_CLAIMS:
      return {
        ...state,
        manualClaims: [
          ...(state.manualClaims || []).slice(0, action.payload.index),
          ...(state.manualClaims || []).slice(action.payload.index + 1),
        ],
      };
    case actionTypes.UPDATE_SELF_INSURED_RETENTION:
      const oldSelfInsuredRetentions = state.selfInsuredRetentions || [];
      return {
        ...state,
        selfInsuredRetentions: [
          ...oldSelfInsuredRetentions.slice(0, action.payload.index),
          {
            ...oldSelfInsuredRetentions[action.payload.index],
            ...action.payload.retention,
          },
          ...oldSelfInsuredRetentions.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.DELETE_SELF_INSURED_RETENTION:
      return {
        ...state,
        selfInsuredRetentions: [
          ...(state.selfInsuredRetentions || []).slice(0, action.payload.index),
          ...(state.selfInsuredRetentions || []).slice(
            action.payload.index + 1
          ),
        ],
      };
    case actionTypes.CLAIMS_TABLE_ADD_LOSS_YEAR:
      return {
        ...state,
        lossYears: [...new Set([...(state.lossYears || []), action.payload])],
      };
    case actionTypes.CLAIMS_TABLE_REMOVE_LOSS_YEAR:
      return {
        ...state,
        lossYears: (state.lossYears || []).filter(
          (year) => year !== action.payload
        ),
      };
    case actionTypes.HIDE_CLAIMS_FILE:
      return {
        ...state,
        files: (state.files || []).filter(
          (file) => file.claimsKey !== action.payload
        ),
        hiddenFiles: [
          ...(state.hiddenFiles || []),
          ...(state.files || []).filter(
            (file) => file.claimsKey === action.payload
          ),
        ],
      };
    case actionTypes.SET_INFLATION_MODEL:
      return {
        ...state,
        inflationModel: action.payload,
      };
    case actions.updateTotalLossNumberFormat.toString():
      if (state.activeFile) {
        return {
          ...state,
          activeFile: {
            ...state.activeFile,
            totalLossNumberFormat: action.payload,
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default claimsReducer;
