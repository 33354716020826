import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import * as numbers from "common/numbers";
import Component from "components/Component";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as modelStore from "store/modelDataStore";

const MIN_WIDTH = 150;

function PerilsModelled({ ...componentProps }) {
  const dispatch = useDispatch();
  const modelData = useSelector(modelStore.selectModelData);

  const initData = [
    {
      peril: "Earthquake",
      RMS: false,
      exclusions: "",
      TIVModelled: "",
      TIVSubmitted: "",
      lastYrTIVModelled: "",
    },
    {
      peril: "Hurricane",
      RMS: false,
      exclusions: "",
      TIVModelled: "",
      TIVSubmitted: "",
      lastYrTIVModelled: "",
    },
    {
      peril: "Fire Following",
      RMS: false,
      exclusions: "",
      TIVModelled: "",
      TIVSubmitted: "",
      lastYrTIVModelled: "",
    },
    {
      peril: "Other Wind",
      RMS: false,
      exclusions: "",
      TIVModelled: "",
      TIVSubmitted: "",
      lastYrTIVModelled: "",
    },
  ];

  const data = modelData?.metadata?.perils || initData;

  const handleInputChange = (index, field, value) => {
    const updatedPerils = data.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );

    dispatch(
      modelStore.update({
        ...modelData,
        metadata: {
          ...modelData.metadata,
          perils: updatedPerils,
        },
      })
    );
  };
  const clearData = () => {
    dispatch(modelStore.update({ metadata: { perils: initData } }));
  };

  return (
    <Component
      title={"Perils Modelled"}
      options={
        <IconButton color={"primary"} onClick={clearData}>
          <DeleteIcon />
        </IconButton>
      }
      {...componentProps}
    >
      <TableContainer component={Paper}>
        <Table size={"small"}>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component={"th"} scope={"row"}>
                  {row.peril}
                </TableCell>
                <TableCell align={"center"}>
                  <Switch
                    checked={row.RMS}
                    onChange={(e) =>
                      handleInputChange(index, "RMS", e.target.checked)
                    }
                    color={"primary"}
                  />
                </TableCell>
                <TableCell align={"center"} sx={{ padding: 0.5 }}>
                  <TextField
                    type={"text"}
                    InputLabelProps={{ shrink: true }}
                    label={"Exclusions"}
                    value={row.exclusions}
                    onChange={(e) =>
                      handleInputChange(index, "exclusions", e.target.value)
                    }
                    variant={"filled"}
                    fullWidth
                    sx={{ minWidth: MIN_WIDTH }}
                  />
                </TableCell>
                <TableCell align={"center"} sx={{ padding: 0.5 }}>
                  <PrettyNumberTextField
                    type={"text"}
                    InputLabelProps={{ shrink: true }}
                    label={"TIV Modelled"}
                    variant={"filled"}
                    value={row.TIVModelled}
                    onChangeNumber={(value) =>
                      handleInputChange(index, "TIVModelled", value)
                    }
                    fullWidth
                    sx={{ minWidth: MIN_WIDTH }}
                  />
                </TableCell>
                <TableCell align={"center"} sx={{ padding: 0.5 }}>
                  <PrettyNumberTextField
                    type={"text"}
                    InputLabelProps={{ shrink: true }}
                    label={"TIV Sumitted"}
                    value={row.TIVSubmitted}
                    onChangeNumber={(value) =>
                      handleInputChange(index, "TIVSubmitted", value)
                    }
                    variant={"filled"}
                    fullWidth
                    sx={{ minWidth: MIN_WIDTH }}
                  />
                </TableCell>
                <TableCell align={"center"} sx={{ padding: 0.5 }}>
                  <PrettyNumberTextField
                    type={"text"}
                    InputLabelProps={{ shrink: true }}
                    label={"Last Year TIV Modelled"}
                    value={row.lastYrTIVModelled}
                    onChangeNumber={(value) =>
                      handleInputChange(index, "lastYrTIVModelled", value)
                    }
                    variant={"filled"}
                    fullWidth
                    sx={{ minWidth: MIN_WIDTH }}
                  />
                </TableCell>
                <TableCell align={"center"} sx={{ padding: 0.5 }}>
                  <TextField
                    type={"text"}
                    InputLabelProps={{ shrink: true }}
                    label={"% Change"}
                    value={numbers.percentage(2)(
                      row.TIVModelled / row.lastYrTIVModelled - 1
                    )}
                    disabled={true}
                    variant={"filled"}
                    fullWidth
                    sx={{ minWidth: 120 }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
}

export default PerilsModelled;
