import CentredLoader from "./CentredLoader";
import {
  deletionMappingsReducer,
  genErrorListByInputColumn,
  getConversionTypeForColumn,
  mergeCells,
} from "./ClaimsTransformerSupport";
import {
  Card,
  CardActionArea,
  CardContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as config from "config";
import { CLAIMS_MAPPING_IGNORE_VALUE, EXCLUDE_KEY } from "config";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useDoConversionQuery,
  useGetClaimsMappingQuery,
  useGetConversionErrorsQuery,
  useGetHeaderRowQuery,
} from "services/tablesService";
import { setV2ClaimsKey } from "store/actions/input/claims/claimsActions";

const ErrorsTable = ({ errors }) => {
  return (
    <Paper elevation={0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{"Cell"}</TableCell>
              <TableCell>{"Spreadsheet Value"}</TableCell>
              <TableCell>{"Error"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.entries.map((error) => (
              <TableRow key={errors.colChar + error.rowNumber}>
                <TableCell key={"Cell"}>
                  {errors.colChar}
                  {error.rowNumber}
                </TableCell>
                <TableCell key={"value"}>{error.originalValue}</TableCell>
                <TableCell key={"error"}>{error.error.join("; ")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const ClaimsTransformer = ({
  fileId,
  origin,
  tableName,
  headerRows,
  mapping,
  appliedExcess,
  filename,
  tablesList,
  onConversionComplete,
}) => {
  const [columnMappings, setColumnMappings] = useState({});
  const [progressMessage, setProgressMessage] = useState("");
  const [deletionMappings, setDeletionMappings] = useState({});
  const [openClosedMapping, setOpenClosedMapping] = useState(null);
  const [coverageMapping, setCoverageMapping] = useState(null);
  const [coverageDefault, setCoverageDefault] = useState(null);

  useEffect(() => {
    const colMap = {};
    if (mapping) {
      for (const index in mapping) {
        const entry = mapping[index];
        colMap[entry.outputName] = entry.inputColumn;
      }
      setColumnMappings(colMap);
    }
  }, [mapping, setColumnMappings]);

  const { data: headings } = useGetHeaderRowQuery(
    {
      fileId,
      tableName,
      skipRows: origin.row,
      skipColumns: origin.column,
      headerRows,
    },
    { skip: !fileId || !tableName }
  );
  const substitutions = [];
  const rowDeletions = [];

  const addDeletionMapping = useCallback(
    (key, deletions) => {
      const excludeColumn = columnMappings[key];
      if (!excludeColumn && excludeColumn !== 0) return;
      setDeletionMappings(deletionMappingsReducer(excludeColumn, deletions));
    },
    [columnMappings]
  );

  useEffect(() => {
    if (Object.keys(columnMappings ?? {}).length > 0) {
      if (EXCLUDE_KEY in columnMappings) {
        const excMapping = getConversionTypeForColumn(
          mapping,
          EXCLUDE_KEY,
          "IN_DELETE_ROW"
        );
        if (!excMapping) return;
        const excVals = Object.entries(excMapping?.conversions)
          .filter(([_, func]) => func === CLAIMS_MAPPING_IGNORE_VALUE)
          .map(([val, _]) => val);
        if (excVals.length > 0) {
          addDeletionMapping(EXCLUDE_KEY, excVals);
        }
      }
    }
  }, [
    columnMappings,
    setDeletionMappings,
    deletionMappings,
    mapping,
    addDeletionMapping,
  ]);

  useEffect(() => {
    const generateMappingDataForEnumCol = (
      colName,
      mappingSetter,
      existing
    ) => {
      const enMapping = getConversionTypeForColumn(mapping, colName, "CONVERT")
        ?.conversions;
      if (!enMapping) return;
      const disFilter = Object.entries(enMapping)
        .filter(([_, mapped]) => mapped === config.CLAIMS_MAPPING_IGNORE_VALUE)
        .map(([val, _]) => val);
      addDeletionMapping(colName, disFilter);
      const enFilter = Object.fromEntries(
        Object.entries(enMapping).filter(
          ([_, mapped]) => mapped !== config.CLAIMS_MAPPING_IGNORE_VALUE
        )
      );
      if (JSON.stringify(enFilter) !== JSON.stringify(existing ?? {})) {
        mappingSetter(enFilter);
      }
    };

    generateMappingDataForEnumCol(
      "_OPEN_CLOSED",
      setOpenClosedMapping,
      openClosedMapping
    );

    const coverageConversion = getConversionTypeForColumn(
      mapping,
      "_COVERAGE",
      "CONSTANT"
    );

    if (coverageConversion) {
      setCoverageDefault(coverageConversion.value);
    } else {
      generateMappingDataForEnumCol(
        "_COVERAGE",
        setCoverageMapping,
        coverageMapping
      );
    }
  }, [
    mapping,
    setOpenClosedMapping,
    setCoverageMapping,
    coverageMapping,
    openClosedMapping,
    addDeletionMapping,
  ]);

  const hasCoverage =
    Object.keys(coverageMapping ?? {}).length > 0 || coverageDefault != null;

  const getExcess = (appliedExcess) => {
    const settings = appliedExcess?.["__DEFAULT__"];
    const type = settings?.type;
    return type === "fgu"
      ? 0
      : type === "excess"
      ? settings["is-excess-removed"]
        ? settings?.excess
        : 0
      : null;
  };

  const getThreshold = (appliedExcess) => {
    const settings = appliedExcess?.["__DEFAULT__"];
    const type = settings?.type;
    return type === "fgu" ? 0 : type === "excess" ? settings?.excess : null;
  };

  const {
    data: mappingTable,
    status: mappingTableStatus,
  } = useGetClaimsMappingQuery(
    {
      fileId,
      columnMappings,
      substitutions,
      rowDeletions,
      deletionMappings: deletionMappings ? Object.values(deletionMappings) : [],
      openClosedMapping,
      openClosedDeleteUnmappedRows: false,
      coverageMapping: coverageMapping ?? [],
      coverageDeleteUnmappedRows: false,
      coverageDefault: coverageDefault ?? "GL",
      excess: getExcess(appliedExcess),
      threshold: getThreshold(appliedExcess),
      defaultDate: "1970-01-01",
    },
    {
      skip: !hasCoverage || !fileId,
    }
  );

  const {
    data: conversionErrors,
    status: conversionErrorsStatus,
  } = useGetConversionErrorsQuery(
    {
      fileId,
      tableName,
      skipColumns: origin.column,
      skipRows: origin.row,
      headerRows,
      skipBlankRows: true,
      mapping: mappingTable,
    },
    { skip: mappingTableStatus !== "fulfilled" || !fileId || !tableName }
  );

  const hasConversionErrors =
    conversionErrorsStatus === "fulfilled" && conversionErrors?.errors?.length;

  const {
    data: processingResponse,
    status: processingResponseStatus,
  } = useDoConversionQuery(
    {
      fileId,
      tableName,
      skipColumns: origin.column,
      skipRows: origin.row,
      headerRows,
      skipBlankRows: true,
      mapping: mappingTable,
      progressNotification: (timing) => {
        if (timing?.rowsProcessed) {
          setProgressMessage(
            `${timing.rowsProcessed} rows processed ` +
              `${timing.percentageComplete}% estimated ` +
              `${timing.remainingSeconds} seconds left`
          );
        }
      },
    },
    {
      skip:
        mappingTableStatus !== "fulfilled" ||
        hasConversionErrors ||
        !fileId ||
        !tableName,
    }
  );

  const dispatch = useDispatch();
  const [createdFile, setCreatedFile] = useState(null);
  useEffect(() => {
    if (
      processingResponseStatus === "fulfilled" &&
      processingResponse.createdFile &&
      createdFile !== processingResponse.createdFile
    ) {
      setCreatedFile(processingResponse.createdFile);
      dispatch(
        setV2ClaimsKey(
          processingResponse.createdFile,
          fileId,
          tableName,
          filename,
          tablesList
        )
      );
      onConversionComplete();
    }
  }, [
    createdFile,
    setCreatedFile,
    processingResponse,
    processingResponseStatus,
    dispatch,
    fileId,
    tableName,
    filename,
    tablesList,
    onConversionComplete,
  ]);

  const errorsByInputColumn = mergeCells(
    genErrorListByInputColumn(conversionErrors, headings, origin),
    origin
  );

  const [activeIndex, setActiveIndex] = useState(0);

  if (
    processingResponseStatus === "rejected" ||
    conversionErrorsStatus === "rejected"
  ) {
    return <>{"Error - failed to load file"}</>;
  } else if (conversionErrorsStatus !== "fulfilled") {
    return <CentredLoader label={"Validating data " + progressMessage} />;
  } else if (hasConversionErrors) {
    return (
      <Stack direction={"column"} spacing={2}>
        {(errorsByInputColumn?.length ?? 0) > 0 && (
          <Stack direction={"column"} spacing={1}>
            <Typography>
              {
                "Marmalade encountered the following issues whilst importing the claims data:"
              }
            </Typography>
            <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
              {errorsByInputColumn.map((error, index) => {
                return (
                  <Card
                    variant={"outlined"}
                    key={index}
                    className={index === activeIndex ? "active" : null}
                    sx={{
                      width: "33%",
                      maxWidth: "14rem",
                      "&.active": {
                        borderColor: "#fa7b35",
                        background: "#fff1e9",
                      },
                    }}
                  >
                    <CardActionArea
                      sx={{ height: "100%" }}
                      onClick={() => setActiveIndex(index)}
                    >
                      <CardContent>
                        <b>
                          {error.entries.length}
                          {error.entries.length === 1 ? " item" : " items"}
                        </b>
                        <Typography>{error.inputColumnName}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
            </Stack>
            <ErrorsTable errors={errorsByInputColumn[activeIndex]} />
          </Stack>
        )}
      </Stack>
    );
  } else {
    return <CentredLoader label={"Validating data " + progressMessage} />;
  }
};

export default ClaimsTransformer;
