import * as paginationSupport from "../common/pagination/Pagination.support";
import PaginationControls from "../common/pagination/PaginationControls";
import { ReviewDownloadButtons } from "./ReviewDownloadButtons";
import ReviewMetrics from "./ReviewMetrics";
import SortDescendingIcon from "@mui/icons-material/KeyboardArrowDown";
import SortAscendingIcon from "@mui/icons-material/KeyboardArrowUp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UnsortedIcon from "@mui/icons-material/UnfoldMore";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Component from "components/Component";
import FilterBar from "components/common/FilterBar";
import FilterSelect from "components/common/FilterSelect";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import SmallLoader from "components/common/SmallLoader";
import Visible from "components/common/Visible";
import * as support from "components/review/ReviewSupport";
import {
  createColumns,
  FILTER_FIELDS,
  getColumnValues,
  LocalLoader,
  mkFilterFieldList,
} from "components/review/ReviewSupport";
import { REVIEW_REPORTS_SHOW_ALL_VALUES_IN_FILTERS } from "flags";
import { useEffect, useState, useLayoutEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import * as reportService from "services/reportService";
import {
  useConfigQuery,
  useFeatureFlagsQuery,
} from "services/staticDataService";
import * as staticDataActions from "store/actions/temp/staticData/staticDataActions";
import * as userSelectors from "store/selectors/user/userSelectors";

const SubmissionTableRow = ({ columns, submission, layer, index }) => {
  return (
    <>
      <TableRow>
        {columns.map(({ Cell, key }) => (
          <Cell key={key} submission={submission} layer={layer} index={index} />
        ))}
        <TableCell>
          <Link
            to={`/submissions/${submission.submissionId}/pricing/layer_pricing/${index}`}
            target={"_blank"}
          >
            <OpenInNewIcon />
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

const SubmissionsTable = ({ columns, columnSort, setColumnSort, ...props }) => {
  const SortArrow = ({ name, columnKey }) => (
    <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
      <span>{name}</span>
      <IconButton
        size={"small"}
        onClick={() => {
          if (columnSort == null || columnSort.columnKey !== columnKey) {
            setColumnSort({
              columnKey,
              ascending: true,
            });
          } else if (columnSort.ascending) {
            setColumnSort({ ...columnSort, ascending: false });
          } else {
            setColumnSort(null);
          }
        }}
      >
        {columnSort == null || columnSort.columnKey !== columnKey ? (
          <UnsortedIcon />
        ) : columnSort.ascending ? (
          <SortAscendingIcon />
        ) : (
          <SortDescendingIcon />
        )}
      </IconButton>
    </Stack>
  );

  return (
    <TableContainer sx={{ maxHeight: props.maxHeight }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key}>
                <SortArrow name={column.title} columnKey={column.key} />
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.submissions
            .map((submission, submissionIndex) => {
              return submission.layers.map((layer, layerIndex) => (
                <SubmissionTableRow
                  columns={columns}
                  key={`${submissionIndex}-${layerIndex}`}
                  submission={submission}
                  layer={layer}
                  index={layerIndex}
                />
              ));
            })
            .flat()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const calculateDynamicDates = (dynamicDate, yearStartMonthIndex) => {
  const today = new Date();
  let from, to;
  switch (dynamicDate) {
    case "YTD":
      from = new Date(today.getFullYear(), yearStartMonthIndex, 1);
      to = today;
      break;
    case "MTD":
      from = new Date(today.getFullYear(), today.getMonth(), 1);
      to = today;
      break;
    case "LAST_QUARTER":
      const lastQuarterMonth = Math.floor((today.getMonth() - 1) / 3) * 3;
      from = new Date(today.getFullYear(), lastQuarterMonth, 1);
      to = new Date(today.getFullYear(), lastQuarterMonth + 3, 0);
      break;
    case "LAST_30_DAYS":
      from = new Date(today);
      from.setDate(today.getDate() - 30);
      to = today;
      break;
    case "LAST_7_DAYS":
      from = new Date(today);
      from.setDate(today.getDate() - 7);
      to = today;
      break;

    default:
      return { from: null, to: null };
  }

  return {
    from: from.toISOString().split("T")[0],
    to: to.toISOString().split("T")[0],
  };
};

const Review = ({ retrieveLayerStatusLabels, userConfig }) => {
  const {
    data: flags,
    isLoading: isFeatureFlagsLoading,
  } = useFeatureFlagsQuery();

  const showAllValuesInFilters =
    flags?.[REVIEW_REPORTS_SHOW_ALL_VALUES_IN_FILTERS] ?? false;

  const { data: columnConfig, isLoading: columnConfigLoading } = useConfigQuery(
    "submission_review_config",
    {
      skip: isFeatureFlagsLoading,
    }
  );

  const { data: dateFormat, isLoading: isDateFormatLoading } = useConfigQuery(
    "date_format"
  );

  const columns = useMemo(() => createColumns(columnConfig), [columnConfig]);

  const searchableColumns = useMemo(
    () => columns.filter((c) => c.isSearchable).map((c) => c.key),
    [columns]
  );

  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const dynamicDate = searchParams.get("date__dynamic");
    const yearStartMonthIndex = dateFormat?.yearStartMonthIndex ?? 0;
    if (dynamicDate) {
      const { from, to } = calculateDynamicDates(
        dynamicDate,
        yearStartMonthIndex
      );
      if (from && to) {
        setDateFilter({ from, to });
      }
    }
  }, [searchParams, dateFormat]);

  useEffect(() => {
    if (
      !isFeatureFlagsLoading &&
      !columnConfigLoading &&
      !isDateFormatLoading &&
      !filters.length
    ) {
      setFilters(mkFilterFieldList({ columns, searchParams }));
    }
  }, [
    filters,
    searchParams,
    columns,
    isFeatureFlagsLoading,
    isDateFormatLoading,
    columnConfigLoading,
  ]);

  useEffect(() => {
    retrieveLayerStatusLabels();
  }, [retrieveLayerStatusLabels]);

  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const [query, setQuery] = useState({});

  const [dateFilter, setDateFilter] = useState({
    from: searchParams?.get("date__gte") ?? null,
    to: searchParams?.get("date__lte") ?? null,
  });

  const [searchInput, setSearchInput] = useState(null);

  useEffect(() => {
    let args = {};
    const filtsList = filters.reduce((res, filter) => {
      if (filter.value?.length) {
        res.push({ key: filter.name, values: filter.value });
      }
      return res;
    }, []);
    if (filtsList?.length) {
      if (showAllValuesInFilters) args["orIns"] = filtsList;
      else args["andIns"] = filtsList;
    }

    if (dateFilter.from) args["dateFrom"] = dateFilter.from;
    if (dateFilter.to) args["dateTo"] = dateFilter.to;

    if (searchInput?.trim()) {
      args["searchString"] = searchInput.trim();
      args["searchColumns"] = searchableColumns;
    }
    if (!columnConfigLoading) {
      args["requiredColumns"] = columns.map((e) => e.key);
    }
    setQuery({
      type: "review_list",
      args,
      format: "json",
      filename: "review_list",
    });
  }, [
    showAllValuesInFilters,
    columnConfigLoading,
    setQuery,
    dateFilter,
    searchInput,
    searchableColumns,
    columns,
    filters,
  ]);

  const {
    data: flat_submissions,
    isLoading: isReportLoading,
    isFetching,
  } = reportService.useReportQuery(query, {
    skip:
      isFeatureFlagsLoading ||
      isDateFormatLoading ||
      columnConfigLoading ||
      !columns.length,
  });

  const isLoading =
    isReportLoading ||
    isFeatureFlagsLoading ||
    isDateFormatLoading ||
    columnConfigLoading;

  const submissions = support.structureFlattenedData(flat_submissions);

  const {
    data: unfilteredSubmissions,
    isLoading: unfilteredLoading,
  } = reportService.useReportQuery(
    {
      type: "review_list",
      args: { requiredColumns: FILTER_FIELDS },
      format: "json",
      filename: "review_list",
    },
    {
      skip: isFeatureFlagsLoading || !showAllValuesInFilters,
    }
  );

  const [columnSort, setColumnSort] = useState({
    columnKey: "inception",
    ascending: false,
  });

  const sortedColumn =
    columns.filter((c) => c.key === columnSort?.columnKey)?.[0] ?? columns[0];

  const updateFilter = (context, value) => {
    const updated = filters.map((item) => {
      return {
        ...item,
        value: item.context === context ? value : item.value,
      };
    });
    setFilters(updated);
  };

  const filterDialogDefault = {
    open: false,
    context: null,
    items: [],
    sortItems: true,
    initialSelected: [],
  };
  const [filterDialog, setFilterDialog] = useState(filterDialogDefault);

  const getFilteredSubmissions = () =>
    isLoading ? [] : submissions.filter((s) => s.layers?.length);

  const getMetricsSubmissions = () => {
    const filteredSubList = getFilteredSubmissions();
    return filteredSubList.map((sub) => sub?.layers).flat();
  };

  const getDisplayableSubmissions = () => {
    const filteredSubList = getFilteredSubmissions();
    const flattened = filteredSubList.flatMap((s) =>
      sortedColumn?.splitLayersOnSort
        ? (s.layers ?? []).map((layer) => ({ ...s, layers: [layer] }))
        : [s]
    );

    if (sortedColumn?.compare) {
      flattened.sort(
        support.invertComparatorIf(!columnSort?.ascending)(sortedColumn.compare)
      );
    }
    return flattened;
  };
  const displayableSubmissions = getDisplayableSubmissions();

  const openFilterDialog = (context) => {
    const filter = filters.filter((x) => x.context === context)[0];
    setFilterDialog({
      ...filterDialog,
      open: true,
      context: context,
      items: getColumnValues(
        filter.name,
        showAllValuesInFilters ? unfilteredSubmissions : flat_submissions
      ),
      sortItems: true,
      selected: filter.value,
    });
  };

  const closeFilterDialog = () => {
    setFilterDialog(filterDialogDefault);
  };

  const usePagination = !!columnConfig?.pagination?.enabled;
  const pageSizeOptions = columnConfig?.pagination?.pageSizeOptions ?? [
    25,
    50,
    100,
  ];

  const {
    paginatedRows,
    rowCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    pageCount,
  } = paginationSupport.usePagination(displayableSubmissions, {
    defaultPageSize:
      columnConfig?.pagination?.defaultPageSize ?? pageSizeOptions?.[0],
  });

  return (
    <>
      <Component>
        {isLoading ? (
          <LocalLoader />
        ) : (
          <>
            {unfilteredLoading ? (
              <SmallLoader />
            ) : (
              <>
                <FilterBar
                  filters={filters}
                  onClickFilter={(context) => openFilterDialog(context)}
                  onChangeFilters={(filters) => setFilters(filters)}
                  onChangeSearchInput={(searchText) =>
                    setSearchInput(searchText)
                  }
                  options={
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <FormattedDatePicker
                        label={"From"}
                        compact
                        onChangeDate={(date) => {
                          setDateFilter({
                            ...dateFilter,
                            from: date,
                          });
                        }}
                        value={dateFilter?.from ?? ""}
                      />
                      <FormattedDatePicker
                        label={"To"}
                        compact
                        onChangeDate={(date) => {
                          setDateFilter({
                            ...dateFilter,
                            to: date,
                          });
                        }}
                        value={dateFilter?.to ?? ""}
                      />
                      <ReviewDownloadButtons
                        name={"SubmissionReview"}
                        type={"review_list"}
                        filterArgs={query}
                      />
                    </Stack>
                  }
                />
                <FilterSelect
                  context={filterDialog.context}
                  items={filterDialog.items}
                  sortItems={filterDialog.sortItems}
                  initialSelected={filterDialog.selected}
                  open={filterDialog.open}
                  onClose={closeFilterDialog}
                  onChange={updateFilter}
                />
              </>
            )}
            {isFetching ? (
              <LocalLoader />
            ) : (
              <>
                <Visible byTag={"review.metrics"} defaultVisible={true}>
                  <ReviewMetrics submissions={getMetricsSubmissions()} />
                </Visible>
                <SubmissionsTable
                  columns={columns}
                  columnSort={columnSort}
                  setColumnSort={setColumnSort}
                  submissions={
                    usePagination ? paginatedRows : displayableSubmissions
                  }
                  maxHeight={height - 350}
                />
                {usePagination && (
                  <PaginationControls
                    rowCount={rowCount}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageSizeOptions={pageSizeOptions}
                    pageCount={pageCount}
                    sx={{ marginTop: 5 }}
                  />
                )}
              </>
            )}
          </>
        )}
      </Component>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userConfig: userSelectors.selectUserConfig(state),
  };
};

const mapDispatchToProps = {
  retrieveLayerStatusLabels:
    staticDataActions.retrieveLayerStatusLabels.requested,
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
