import Broker from "./Broker";
import CategoryCode from "./CategoryCode";
import Duns from "./Duns";
import IndustryClass from "./IndustryClass";
import ModelSelector from "./ModelSelector";
import OldBroker from "./OldBroker";
import PersonSelect from "./PersonSelect";
import ProgramTiv from "./ProgramTiv";
import ReinsuranceAggregator from "./ReinsuranceAggregator";
import Renewal from "./Renewal";
import StateOfFiling from "./StateOfFiling";
import SurplusBroker from "./SurplusBroker";
import Tower from "./Tower";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Component from "components/Component";
import DataScore from "components/common/DataScore";
import Disabled from "components/common/Disabled";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import HiddenIfSnapshot from "components/common/HiddenIfSnapshot";
import Notes from "components/common/Notes";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import Visible from "components/common/Visible";
import SelfInsuredRetentions from "components/inputs/claims/SelfInsuredRetentions";
import ActiveLayerEditor from "components/layers/ActiveLayerEditor";
import ActiveLayerPremiumAdjustmentsEditor from "components/layers/ActiveLayerPremiumAdjustmentsEditor";
import ActiveLayerSelector from "components/layers/ActiveLayerSelector";
import ReinsuranceSelector from "components/layers/ReinsuranceSelector";
import PaymentTermsForm from "components/payments/PaymentTermsForm";
import { makeSearch } from "components/search/SearchTextField";
import Share from "components/transfer/Share";
import * as config from "config";
import { connect } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as programActions from "store/actions/input/program/programActions";
import {
  updatePreferredUnderwriter,
  updatePreferredUnderwriterName,
  updateSecondaryUnderwriter,
} from "store/actions/people/peopleActions";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as layerDefaultsStore from "store/layerDefaults";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import {
  selectSecondaryUnderwriter,
  selectPreferredUnderwriter,
} from "store/selectors/people/peopleSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const US = "US";
const NON_US = "NON_US";

const NAICSSearch = makeSearch((state) => ({
  searchState: state.search.naics,
  active: state.input.program.naics,
  meta: config.NAICS_SEEKER,
  displayResultText: (result) => `(${result._CODE}) ${result._TITLE}`,
  renderValue: (result) => `(${result.code}) ${result.title}`,
  error: programSelectors.isPricingNaicsInError(state),
}));

const CompanyNAICSSearch = makeSearch(
  (state) => ({
    searchState: state.search.companyNaics,
    active: programSelectors.getCompanyNaics(state),
    meta: config.COMPANY_NAICS_SEEKER,
    displayResultText: (result) => `(${result._CODE}) ${result._TITLE}`,
    renderValue: (result) => `(${result.code}) ${result.title}`,
  }),
  undefined,
  programActions.clearCompanyNaics
);

const StatusOverrideSelector = ({ program, updateProgram }) => {
  const { data: statusLabels } = staticDataService.useConfigQuery(
    "layer_status_labels_v2"
  );

  const NULL_KEY = "__NULL__";

  return (
    <Disabled ifReadOnly>
      <FormControl variant={"filled"} fullWidth>
        <InputLabel shrink={true}>{"Status Override"}</InputLabel>
        <Select
          value={program?.status ?? NULL_KEY}
          onChange={(event) => {
            const status = event.target.value;
            updateProgram({
              status: status === NULL_KEY ? null : status,
            });
          }}
        >
          <MenuItem value={NULL_KEY}>
            {program?.status == null ? "" : "[Reset to Default]"}
          </MenuItem>
          {(statusLabels?.labels ?? [])
            .filter(({ statusKey }) => statusKey !== "DIVIDER")
            .map(({ statusKey }) => (
              <MenuItem key={statusKey} value={statusKey}>
                <StatusBadgeV2 status={statusKey} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Disabled>
  );
};

export const Program = (props) => {
  const { data: brokers } = staticDataService.useListBrokersQuery();

  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  return (
    <>
      <Visible byTag={"input.program.riskSummary"} defaultVisible={true}>
        <Grid item xs={12}>
          <Notes
            title={"Risk Summary"}
            md_width={12}
            update={props.updateRiskSummary}
            value={props.riskSummary}
          />
        </Grid>
      </Visible>
      <Visible byTag={"input.program.insuredDetail"} defaultVisible={true}>
        <Grid item md={6} sm={12}>
          <Grid container direction={"column"} spacing={3}>
            <Grid item>
              <Component title={"Insured Details"}>
                <Grid container spacing={config.GRID_SPACING}>
                  <Visible
                    byTag={"input.program.insuredDetail.insured"}
                    defaultVisible={true}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Insured"}
                        data-testid={"insured"}
                        autoComplete={"off"}
                        id={"program-inputs-insured"}
                        variant={"filled"}
                        onChange={(e) => props.updateInsured(e.target.value)}
                        value={props.insured}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.riskDescription"}
                    defaultVisible={true}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Risk Description"}
                        autoComplete={"off"}
                        id={"program-inputs-risk-description"}
                        variant={"filled"}
                        onChange={(e) =>
                          props.updateRiskDescription(e.target.value)
                        }
                        value={props.riskDescription}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.insuredAddress"}
                    defaultVisible={true}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Insured Address"}
                        autoComplete={"off"}
                        id={"program-insured-address"}
                        variant={"filled"}
                        onChange={(e) =>
                          props.updateInsuredAddress(e.target.value)
                        }
                        value={props.insuredAddress}
                        fullWidth
                        multiline
                        rows={3}
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.inception"}
                    defaultVisible={true}
                  >
                    <Grid item xs={4}>
                      <FormattedDatePicker
                        label={"Inception"}
                        variant={"filled"}
                        onChangeDate={props.updateInception}
                        value={props.inception ?? ""}
                        fullWidth
                        clearable={false}
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.expiration"}
                    defaultVisible={true}
                  >
                    <Grid item xs={4}>
                      <FormattedDatePicker
                        label={"Expiration"}
                        onChangeDate={props.updateExpiration}
                        value={props.expiration ?? ""}
                        fullWidth
                        clearable={false}
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.proRata"}
                    defaultVisible={true}
                  >
                    <Grid item xs={4}>
                      <PrettyNumberTextField
                        label={"Pro Rata"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        autoComplete={"off"}
                        variant={"percentage"}
                        onChangeNumber={props.updateProRataAdjustment}
                        value={props.proRataAdjustment ?? null}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.transaction"}
                    defaultVisible={false}
                  >
                    <Grid item xs={6}>
                      <Disabled ifReadOnly>
                        <FormControl variant={"filled"} fullWidth>
                          <InputLabel shrink={true}>{"Transaction"}</InputLabel>
                          <Select
                            value={props.transaction}
                            onChange={(event) =>
                              props.updateProgram({
                                transaction: event.target.value,
                              })
                            }
                          >
                            <MenuItem value={"NEW"}>{"New Business"}</MenuItem>
                            <MenuItem value={"RENEWAL"}>{"Renewal"}</MenuItem>
                            <MenuItem value={"MTA"}>{"MTA"}</MenuItem>
                            <MenuItem value={"CANCELLATION"}>
                              {"Cancellation"}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Disabled>
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.effectiveDate"}
                    defaultVisible={false}
                  >
                    <Grid item xs={6}>
                      <FormattedDatePicker
                        label={"Effective Date"}
                        onChangeDate={(effectiveDate) =>
                          props.updateProgram({ effectiveDate })
                        }
                        value={props.effectiveDate ?? ""}
                        fullWidth
                        clearable={false}
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.reference"}
                    defaultVisible={true}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={translateLabel("PROGRAM_REFERENCE", {
                          default: "Reference",
                        })}
                        autoComplete={"off"}
                        variant={"filled"}
                        onChange={(e) => props.updateReference(e.target.value)}
                        value={props.reference}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.quoteReference"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={translateLabel("QUOTE_REFERENCE", {
                          default: "Quote Reference",
                        })}
                        autoComplete={"off"}
                        variant={"filled"}
                        onChange={(e) =>
                          props.updateProgram({
                            quoteReference: e.target.value,
                          })
                        }
                        value={props.quoteReference ?? ""}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.preferredUnderwriter"}
                    defaultVisible={true}
                  >
                    <Grid
                      container
                      item
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      spacing={config.GRID_SPACING}
                    >
                      {(props.underwriterList ?? []).length > 0 ? (
                        <>
                          <Grid item xs={true}>
                            <Disabled ifReadOnly>
                              <PersonSelect
                                style={{ alignSelf: "flex-start" }}
                                label={"Preferred Underwriter"}
                                person={props.preferredUnderwriter}
                                personList={props.underwriterList}
                                onUpdatePerson={
                                  props.updatePreferredUnderwriter
                                }
                                onUpdatePersonList={() => {}}
                                showCompany={false}
                                showEmail={false}
                                showLabelOnCard={true}
                              />
                            </Disabled>
                          </Grid>
                          {props.userIsUnderwriter &&
                            props.preferredUnderwriter?.id !==
                              props.user?.id && (
                              <Disabled ifReadOnly>
                                <Visible
                                  byTag={
                                    "input.program.insuredDetail.me-underwriter"
                                  }
                                  defaultVisible={true}
                                >
                                  <Grid item xs={"auto"}>
                                    <Button
                                      onClick={() =>
                                        props.updatePreferredUnderwriter(
                                          props.underwriterFromUser
                                        )
                                      }
                                      variant={"contained"}
                                      disableElevation
                                      color={"primary"}
                                    >
                                      {"Me"}
                                    </Button>
                                  </Grid>
                                </Visible>
                              </Disabled>
                            )}
                        </>
                      ) : (
                        <Grid item xs={12}>
                          <TextField
                            type={"text"}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ spellCheck: "false" }}
                            label={"Preferred Underwriter"}
                            autoComplete={"off"}
                            id={"program-preferred-underwriter"}
                            variant={"filled"}
                            onChange={(e) =>
                              props.updatePreferredUnderwriterName(
                                e.target.value
                              )
                            }
                            value={props.preferredUnderwriterName}
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.secondaryUnderwriter"}
                    defaultVisible={false}
                  >
                    <Grid
                      container
                      item
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      spacing={config.GRID_SPACING}
                    >
                      {props.underwriterList?.length && (
                        <>
                          <Grid item xs={true}>
                            <Disabled ifReadOnly>
                              <PersonSelect
                                style={{ alignSelf: "flex-start" }}
                                label={"Secondary Underwriter"}
                                person={props.secondaryUnderwriter}
                                personList={props.underwriterList}
                                onUpdatePerson={
                                  props.updateSecondaryUnderwriter
                                }
                                onUpdatePersonList={() => {}}
                                showCompany={false}
                                showEmail={false}
                                showLabelOnCard={true}
                              />
                            </Disabled>
                          </Grid>
                          {props.userIsUnderwriter &&
                            props.preferredUnderwriter?.id !==
                              props.user?.id && (
                              <Disabled ifReadOnly>
                                <Visible
                                  byTag={
                                    "input.program.insuredDetail.me-underwriter"
                                  }
                                  defaultVisible={true}
                                >
                                  <Grid item xs={"auto"}>
                                    <Button
                                      onClick={() =>
                                        props.updateSecondaryUnderwriter(
                                          props.underwriterFromUser
                                        )
                                      }
                                      variant={"contained"}
                                      disableElevation
                                      color={"primary"}
                                    >
                                      {"Me"}
                                    </Button>
                                  </Grid>
                                </Visible>
                              </Disabled>
                            )}
                        </>
                      )}
                    </Grid>
                  </Visible>
                  <Grid item container xs={12} spacing={config.GRID_SPACING}>
                    <Visible
                      byTag={"input.program.insuredDetail.dateReceived"}
                      defaultVisible={true}
                    >
                      <Grid item xs={4}>
                        <FormattedDatePicker
                          label={translateLabel("DATE_RECEIVED", {
                            default: "Date Received",
                          })}
                          onChangeDate={(dateReceived) =>
                            props.updateProgram({ dateReceived })
                          }
                          value={props.dateReceived ?? ""}
                          fullWidth
                          clearable={false}
                        />
                      </Grid>
                    </Visible>
                    <Visible
                      byTag={"input.program.insuredDetail.quoteDeadline"}
                      defaultVisible={true}
                    >
                      <Grid item xs={4}>
                        <FormattedDatePicker
                          label={translateLabel("QUOTE_DEADLINE", {
                            default: "Quote Deadline",
                          })}
                          onChangeDate={(quoteDeadline) =>
                            props.updateProgram({ quoteDeadline })
                          }
                          value={props.quoteDeadline ?? ""}
                          fullWidth
                          clearable={false}
                        />
                      </Grid>
                    </Visible>
                    <Visible
                      byTag={"input.program.insuredDetail.quoteDate"}
                      defaultVisible={true}
                    >
                      <Grid item xs={4}>
                        <FormattedDatePicker
                          label={translateLabel("QUOTE_DATE", {
                            default: "Quote Date",
                          })}
                          onChangeDate={(quoteDate) =>
                            props.updateProgram({ quoteDate })
                          }
                          value={props.quoteDate ?? ""}
                          fullWidth
                          clearable={false}
                        />
                      </Grid>
                    </Visible>
                    <Visible
                      byTag={"input.program.insuredDetail.quoteValidUntil"}
                      defaultVisible={true}
                    >
                      <Grid item xs={4}>
                        <FormattedDatePicker
                          label={translateLabel("QUOTE_VALID_UNTIL", {
                            default: "Quote Valid Until",
                          })}
                          onChangeDate={(quoteValidUntil) =>
                            props.updateProgram({ quoteValidUntil })
                          }
                          value={props.quoteValidUntil ?? ""}
                          fullWidth
                          clearable={false}
                        />
                      </Grid>
                    </Visible>
                    <Visible
                      byTag={"input.program.insuredDetail.status"}
                      defaultVisible={false}
                    >
                      <Grid item xs={4}>
                        <StatusOverrideSelector
                          program={props.program}
                          updateProgram={props.updateProgram}
                        />
                      </Grid>
                    </Visible>
                  </Grid>
                  <Visible
                    byTag={"input.program.insuredDetail.brokerage"}
                    defaultVisible={true}
                  >
                    <Grid item xs={12} md={6}>
                      <PrettyNumberTextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Brokerage"}
                        autoComplete={"off"}
                        id={"program-inputs-brokerage"}
                        variant={"percentage"}
                        onChangeNumber={props.updateBrokerage}
                        value={props.brokerage}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.requestedBrokerage"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12} md={6}>
                      <PrettyNumberTextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Requested Brokerage"}
                        autoComplete={"off"}
                        id={"program-inputs-requested-brokerage"}
                        variant={"percentage"}
                        onChangeNumber={props.updateRequestedBrokerage}
                        value={props.requestedBrokerage}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.oac"}
                    defaultVisible={true}
                  >
                    <Tooltip title={"Other Acquisition Costs %"}>
                      <Grid item xs={12} md={6}>
                        <PrettyNumberTextField
                          type={"text"}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ spellCheck: "false" }}
                          label={"OAC"}
                          autoComplete={"off"}
                          id={"program-inputs-other-acquisition-costs"}
                          variant={"percentage"}
                          onChangeNumber={props.updateOtherAcquisitionCosts}
                          value={props.otherAcquisitionCosts}
                          fullWidth
                        />
                      </Grid>
                    </Tooltip>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.targetProfit"}
                    defaultVisible={true}
                  >
                    <Grid item xs={12} md={6}>
                      <PrettyNumberTextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Target Profit"}
                        autoComplete={"off"}
                        id={"program-inputs-profit"}
                        variant={"percentage"}
                        onChangeNumber={props.updateProfit}
                        value={props.profit}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.gnulr"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12} md={6}>
                      <Disabled
                        byTag={"input.program.insuredDetail.gnulr"}
                        defaultDisabledByTag={false}
                      >
                        <PrettyNumberTextField
                          type={"text"}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ spellCheck: "false" }}
                          label={"GNULR"}
                          autoComplete={"off"}
                          id={"program-inputs-gnulr"}
                          variant={"percentage"}
                          onChangeNumber={(value) => {
                            props.updateProfit(100 - value);
                          }}
                          value={100 - props.profit}
                          fullWidth
                        />
                      </Disabled>
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.expenses"}
                    defaultVisible={true}
                  >
                    <Grid item xs={12} md={6}>
                      <PrettyNumberTextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Expenses"}
                        autoComplete={"off"}
                        id={"program-inputs-expenses"}
                        variant={"percentage"}
                        onChangeNumber={props.updateExpenses}
                        value={props.expenses}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible byTag={"input.program.insuredDetail.paymentTerms"}>
                    <PaymentTermsForm
                      paymentTerms={props.paymentTerms}
                      setPaymentTerms={props.updatePaymentTerms}
                    />
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.naicsForPricing"}
                    defaultVisible={true}
                  >
                    <Grid item xs={6}>
                      <NAICSSearch
                        label={"NAICS for Pricing"}
                        data-testid={"NAICS-search"}
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.naicsOfCompany"}
                    defaultVisible={true}
                  >
                    <Grid item xs={6}>
                      <CompanyNAICSSearch label={"NAICS of Company"} />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.inUsLocation"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12} sm={5}>
                      <FormControl component={"fieldset"} variant={"standard"}>
                        <FormLabel
                          component={"legend"}
                          className={"input-label"}
                        >
                          {"Location"}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label={"in-us"}
                          id={"program-inputs-in-us"}
                          name={"in-us"}
                          value={props.inUS ? US : NON_US}
                          onChange={(event) => {
                            props.updateWhetherInUS(event.target.value === US);
                          }}
                        >
                          <FormControlLabel
                            value={US}
                            control={<Radio color={"primary"} />}
                            label={"US"}
                          />
                          <FormControlLabel
                            value={NON_US}
                            control={<Radio color={"primary"} />}
                            label={"Non-US"}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.turnoverCategory"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12} sm={7}>
                      <Disabled ifReadOnly>
                        <FormControl variant={"filled"} fullWidth>
                          <InputLabel className={"input-label"}>
                            {"Turnover"}
                          </InputLabel>
                          <Select
                            value={props.turnover}
                            onChange={(event) =>
                              props.updateTurnover(event.target.value)
                            }
                            inputProps={{
                              name: "program-inputs-turnover",
                              id: "program-inputs-turnover",
                            }}
                            name={"turnover"}
                            className={"program-inputs-turnover"}
                          >
                            {config.TURNOVERS.map((turnover) => {
                              return (
                                <MenuItem
                                  key={`Turnover_Item_${turnover.key}`}
                                  value={turnover.key}
                                >
                                  {turnover.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Disabled>
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.currency"}
                    defaultVisible={true}
                  >
                    <Grid item xs={4}>
                      <Disabled ifReadOnly>
                        <Autocomplete
                          options={props.currencyCodesList}
                          renderInput={(params) => (
                            <TextField
                              label={"Currency"}
                              variant={"filled"}
                              fullWidth
                              {...params}
                            />
                          )}
                          disableClearable
                          autoHighlight
                          onChange={(event, value, reason) => {
                            if (reason === "selectOption") {
                              props.updateCurrency(value);
                            }
                          }}
                          value={props.currency}
                        />
                      </Disabled>
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.reassured"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12}>
                      <TextField
                        type={"text"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ spellCheck: "false" }}
                        label={"Reassured"}
                        autoComplete={"off"}
                        variant={"filled"}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (!value || value === "") {
                            value = null;
                          }
                          props.updateReassured(value);
                        }}
                        value={props.reassured ?? ""}
                        fullWidth
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.reinsurance"}
                    defaultVisible={false}
                  >
                    <ReinsuranceAggregator
                      label={"Security"}
                      layers={props.layers}
                      onChange={props.updateAllLayersReinsurance}
                    />
                  </Visible>
                  <Visible
                    byTag={"input.program.insuredDetail.defaultPaperProvider"}
                    defaultVisible={false}
                  >
                    <ReinsuranceSelector
                      label={translateLabel("DEFAULT_PAPER_PROVIDER", {
                        default: "Default Paper Provider",
                      })}
                      value={props.layerDefaults?.paperProvider ?? null}
                      onChange={(paperProvider) => {
                        props.updateLayerDefaults({ paperProvider });
                      }}
                    />
                  </Visible>
                  <Visible
                    byTag={"input.program.stateOfFiling"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12}>
                      <StateOfFiling
                        value={props.stateOfFiling}
                        onChange={props.updateStateOfFiling}
                      />
                    </Grid>
                  </Visible>
                  <Visible
                    byTag={"input.program.surplusBroker"}
                    defaultVisible={false}
                  >
                    <Grid item xs={12}>
                      <SurplusBroker />
                    </Grid>
                  </Visible>
                </Grid>
              </Component>
            </Grid>
          </Grid>
        </Grid>
      </Visible>
      <Grid item md={6} sm={12}>
        <Grid container direction={"column"} spacing={3}>
          {((props.userConfig?.componentVisibility?.[
            "input.program.industryClass"
          ] ??
            true) ||
            (props.userConfig?.componentVisibility?.[
              "input.program.modelSelector"
            ] ??
              true) ||
            (props.userConfig?.componentVisibility?.[
              "input.program.categoryCode"
            ] ??
              true)) && (
            <Grid item>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                spacing={config.GRID_SPACING}
              >
                <Visible
                  byTag={"input.program.industryClass"}
                  defaultVisible={true}
                >
                  <IndustryClass />
                </Visible>
                <Visible
                  byTag={"input.program.modelSelector"}
                  defaultVisible={false}
                >
                  <ModelSelector />
                </Visible>
                <Visible
                  byTag={"input.program.categoryCode"}
                  defaultVisible={true}
                >
                  {props.categoryCodeVisible ? <CategoryCode /> : <></>}
                </Visible>
              </Stack>
            </Grid>
          )}
          <Visible byTag={"input.program.broker"} defaultVisible={true}>
            {brokers?.length ? <Broker /> : <OldBroker />}
          </Visible>
          <Visible byTag={"input.program.duns"} defaultVisible={false}>
            <Duns />
          </Visible>
          <HiddenIfSnapshot>
            <Visible byTag={"input.program.share"} defaultVisible={false}>
              <Share />
            </Visible>
          </HiddenIfSnapshot>
          <Visible byTag={"input.program.renewal"} defaultVisible={true}>
            <Renewal />
          </Visible>
          <Visible byTag={"input.program.programTiv"} defaultVisible={false}>
            <ProgramTiv />
          </Visible>
          <Visible byTag={"input.program.datascore"} defaultVisible={false}>
            <Grid item>
              <Component title={"Data Quality"}>
                <Disabled ifReadOnly>
                  <DataScore
                    initialValue={props.dataQuality}
                    variant={"select"}
                    onChange={props.updateDataQuality}
                  />
                </Disabled>
              </Component>
            </Grid>
          </Visible>
        </Grid>
      </Grid>
      <Visible byTag={"input.program.tower"} defaultVisible={false}>
        <Grid item xs={12}>
          <Tower />
        </Grid>
      </Visible>
      <Visible
        byTag={"input.program.selfInsuredRetentions"}
        defaultVisible={false}
      >
        <Grid item xs={12}>
          <SelfInsuredRetentions />
        </Grid>
      </Visible>
      <Visible byTag={"input.program.layerInput"}>
        <Grid item xs={12}>
          <ActiveLayerSelector
            includedColumnKeys={[
              "delete",
              "status",
              "copyId",
              "shareOfLimit",
              "limit",
              "attachment",
              "grossPremium",
              "grossPPM",
              "shareOfNetPremium",
              "tac",
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ActiveLayerEditor />
        </Grid>
        <Grid item xs={12} md={6}>
          <ActiveLayerPremiumAdjustmentsEditor />
        </Grid>
      </Visible>
      <Visible byTag={"input.program.notes"} defaultVisible={true}>
        <Notes
          title={"Program Notes"}
          update={props.updateNotes}
          value={props.notes}
        />
      </Visible>
    </>
  );
};

const mapDispatchToProps = {
  updateProgram: programActions.updateProgram,
  updateInception: programActions.updateInception,
  updateRiskDescription: programActions.updateRiskDescription,
  updateInsured: programActions.updateInsured,
  updateReference: programActions.updateReference,
  updateWhetherInUS: programActions.updateWhetherInUS,
  updateTurnover: programActions.updateTurnover,
  updateBrokerage: programActions.updateBrokerage,
  updateExpenses: programActions.updateExpenses,
  updateProfit: programActions.updateProfit,
  updateExpiration: programActions.updateExpiration,
  updateProRataAdjustment: programActions.updateProRataAdjustment,
  updateOtherAcquisitionCosts: programActions.updateOtherAcquisitionCosts,
  updateInsuredAddress: programActions.updateInsuredAddress,
  updatePreferredUnderwriter: updatePreferredUnderwriter,
  updatePreferredUnderwriterName: updatePreferredUnderwriterName,
  updateSecondaryUnderwriter: updateSecondaryUnderwriter,
  updateCurrency: programActions.updateCurrency,
  updateReassured: programActions.updateReassured,
  updatePaymentTerms: programActions.updatePaymentTerms,
  updateStateOfFiling: programActions.updateStateOfFiling,
  updateNotes: programActions.updateNotes,
  updateRiskSummary: programActions.updateRiskSummary,
  updateAllLayersReinsurance: pricingActions.updateAllLayersReinsurance,
  updateDataQuality: programActions.updateDataQuality,
  updateRequestedBrokerage: programActions.updateRequestedBrokerage,
  updateLayerDefaults: layerDefaultsStore.update,
};

const mapStateToProps = (state) => {
  const program = state.input.program;
  const resolvedProgram = programSelectors.selectResolvedProgram(state);
  const layerDefaults = layerDefaultsStore.select(state);
  return {
    ...resolvedProgram,
    inception: program.inception,
    insured: program.insured,
    riskDescription: program.riskDescription,
    reference: program.reference,
    quoteReference: program.quoteReference,
    inUS: program.inUS,
    turnover: program.turnoverCategory,
    brokerage: resolvedProgram?.brokerage ?? 0,
    expenses: resolvedProgram?.expenses ?? 0,
    profit: resolvedProgram?.profit ?? 0,
    otherAcquisitionCosts: resolvedProgram?.otherAcquisitionCosts ?? 0,
    expiration: resolvedProgram?.expiration ?? null,
    proRataAdjustment: resolvedProgram?.proRataAdjustment ?? null,
    insuredAddress: program.insuredAddress,
    preferredUnderwriter: selectPreferredUnderwriter(state),
    preferredUnderwriterName: selectPreferredUnderwriter(state)?.name ?? "",
    secondaryUnderwriter: selectSecondaryUnderwriter(state),
    categoryCodeVisible: state.temp?.flags?.categoryCodeVisible || false,
    userConfig: userSelectors.selectUserConfig(state),
    currency: program.currency,
    reassured: program.reassured,
    currencyCodesList: staticDataSelectors.selectCurrencyCodes(state),
    paymentTerms: resolvedProgram?.paymentTerms ?? null,
    notes: program.notes,
    riskSummary: program.riskSummary,
    underwriterList: staticDataSelectors.selectUnderwriterList(state),
    userIsUnderwriter:
      staticDataSelectors.selectUnderwriterFromUser(state) != null,
    underwriterFromUser: staticDataSelectors.selectUnderwriterFromUser(state),
    stateOfFiling: program.stateOfFiling,
    isUserReadOnly: userSelectors.isUserReadOnly(state),
    layers: pricingSelectors.selectResolvedLayers(state),
    dataQuality: program.dataQuality,
    user: userSelectors.selectUser(state).attributes,
    requestedBrokerage: resolvedProgram?.requestedBrokerage ?? null,
    layerDefaults,
    program,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Program);
