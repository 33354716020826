import * as paymentResolvers from "./paymentResolvers";
import { extractBrokerFromPeople } from "./people";

export const resolvePremiumAdjustment = (
  premiumAdjustment,
  { layer, program, people }
) => {
  if (!premiumAdjustment) {
    return null;
  }

  const shareOfLimitFractionOfLimit =
    layer.shareOfLine != null
      ? 0.01 * layer.shareOfLine
      : layer.limit != null && layer.lineSize != null && layer.limit !== 0
      ? layer.lineSize / layer.limit
      : null;

  const grossPremiumChange = premiumAdjustment.grossPremiumChange ?? 0;
  const defaultShareOfGrossPremiumChange =
    shareOfLimitFractionOfLimit != null
      ? grossPremiumChange * shareOfLimitFractionOfLimit
      : null;

  const broker = premiumAdjustment.broker ?? extractBrokerFromPeople(people);

  premiumAdjustment = {
    type: "Additional Premium",
    startDate: layer.inception ?? program.inception,
    endDate: program.expiration,
    grossPremiumChange: 0,
    ...premiumAdjustment,
    broker,
    shareOfGrossPremiumChange:
      premiumAdjustment.shareOfGrossPremiumChange ??
      defaultShareOfGrossPremiumChange,
  };

  premiumAdjustment.paymentTerms = paymentResolvers.resolvePaymentTerms(
    premiumAdjustment.paymentTerms ?? layer?.paymentTerms,
    {
      fromDateString: premiumAdjustment.startDate,
    }
  );

  return premiumAdjustment;
};

export const resolveLayer = (
  layer,
  { program, people, claims, layerDefaults }
) => {
  const paymentTerms = paymentResolvers.resolvePaymentTerms(
    layer.paymentTerms ?? program.paymentTerms,
    { fromDateString: program.effectiveDate ?? program.inception }
  );

  const status = layer.status ?? "DRAFT";

  const includedSir = layer?.includeSir
    ? claims?.selfInsuredRetentions?.filter(
        (sir) => sir.lossType === layer?.includeSir
      )?.[0] ?? null
    : null;

  const sir = includedSir?.value ?? null;

  const out = {
    ...layerDefaults,
    ...layer,
    paymentTerms,
    status,
    bound: status === "BOUND",
    brokerage: layer.brokerage ?? program.brokerage ?? 0,
    otherAcquisitionCosts:
      layer.otherAcquisitionCosts ?? program.otherAcquisitionCosts ?? 0,
    slipFee: layer.slipFee ?? 0,
    aggregateLimit: layer.aggregateLimit ?? layer.limit,
    sir,
    effectiveAttachment:
      layer?.attachment != null ? layer.attachment + (sir ?? 0) : null,
    profit: layer.profit ?? program.profit ?? 0,
    expenses: layer.expenses ?? program.expenses ?? 0,
    grossPPM: _layerToGrossPPM(layer),
    grossPremium: _layerToGrossPremium(layer),
    shareOfLine: _layerToShareOfLine(layer),
    lineSize: _layerToLineSize(layer),
    shareOfPremium: _layerToShareOfPremium(layer),
    TRIAAmount: _layerToTRIAAmount(layer),
    shareOfPremiumWithTRIA: _layerToShareOfPremiumWithTRIA(layer),
  };

  out.tac = out.brokerage + out.otherAcquisitionCosts + out.slipFee;

  if (program.targetTotalExpenses != null && layer.expenses == null) {
    const otherExpenses = out.brokerage + out.slipFee;
    out.expenses = program.targetTotalExpenses - otherExpenses;
  }

  if (out.puniWrapPct != null) {
    out.puniWrap =
      Number.isFinite(out.puniWrapPct) && Number.isFinite(out.grossPremium)
        ? out.grossPremium * (out.puniWrapPct / 100)
        : null;

    out.puniWrapBrokerage =
      Number.isFinite(out.puniWrap) && Number.isFinite(out.puniWrapBrokeragePct)
        ? out.puniWrap * (out.puniWrapBrokeragePct / 100)
        : null;

    out.netPuniWrap =
      Number.isFinite(out.puniWrap) && Number.isFinite(out.puniWrapBrokerage)
        ? out.puniWrap - out.puniWrapBrokerage
        : null;

    out.shareOfPuniWrap =
      Number.isFinite(out.shareOfLine) && Number.isFinite(out.puniWrap)
        ? out.puniWrap * (out.shareOfLine / 100)
        : null;

    out.shareOfPremiumExPuniWrap = out.isPuniWrapIncluded
      ? Number.isFinite(out.shareOfPuniWrap) &&
        Number.isFinite(out.shareOfPremium)
        ? out.shareOfPremium - out.shareOfPuniWrap
        : null
      : out.shareOfPremium;

    out.shareOfPuniWrapBrokerage =
      Number.isFinite(out.shareOfPuniWrap) &&
      Number.isFinite(out.puniWrapBrokeragePct)
        ? out.shareOfPuniWrap * (out.puniWrapBrokeragePct / 100)
        : null;
  }

  if (
    layer.limit != null &&
    layer.quotedLineSize != null &&
    layer.quotedGrossPremium != null
  ) {
    out.quotedShareOfPremium =
      layer.quotedGrossPremium * (layer.quotedLineSize / layer.limit);
  }

  out.premiumAdjustments = (layer.premiumAdjustments ?? []).map(
    (premiumAdjustment) =>
      resolvePremiumAdjustment(premiumAdjustment, {
        layer: out,
        program,
        people,
      })
  );

  return out;
};

const _layerToGrossPPM = (layer) => {
  if (layer.grossPPM == null) {
    if (layer.limit == null || layer.grossPremium == null) {
      return null;
    }
    return (layer.grossPremium / layer.limit) * 1e6;
  }
  return layer.grossPPM;
};

const _layerToGrossPremium = (layer) => {
  if (layer.grossPremium == null) {
    if (layer.limit == null || layer.grossPPM == null) {
      return null;
    }
    return (layer.grossPPM / 1e6) * layer.limit;
  }
  return layer.grossPremium;
};

const _layerToShareOfLine = (layer) => {
  if (layer.shareOfLine == null) {
    if (layer.limit == null || layer.lineSize == null) {
      return null;
    }
    return (layer.lineSize / layer.limit) * 100;
  }
  return layer.shareOfLine;
};

const _layerToLineSize = (layer) => {
  if (layer.lineSize == null) {
    if (layer.limit == null || layer.shareOfLine == null) {
      return null;
    }
    return (layer.shareOfLine / 100) * layer.limit;
  }
  return layer.lineSize;
};

export const _layerToShareOfPremium = (layer) => {
  if (layer.shareOfPremium != null) {
    return layer.shareOfPremium;
  }
  const grossPremium = _layerToGrossPremium(layer);
  const shareOfLine = _layerToShareOfLine(layer);
  if (grossPremium == null) {
    return null;
  }
  if (shareOfLine == null) {
    return null;
  }
  return (grossPremium * shareOfLine) / 100;
};

const _layerToTRIA = (layer) => {
  return layer.TRIA ?? null;
};

const _layerToTRIAAmount = (layer) => {
  const grossPremium = _layerToGrossPremium(layer);
  const shareOfLine = _layerToShareOfLine(layer);
  const TRIA = _layerToTRIA(layer);

  if (grossPremium == null || shareOfLine == null || TRIA == null) {
    return null;
  }
  return ((TRIA / 100) * grossPremium * shareOfLine) / 100;
};

export const _layerToShareOfPremiumWithTRIA = (layer) => {
  const shareOfPremium = _layerToShareOfPremium(layer);
  if (shareOfPremium == null) {
    return null;
  }
  const TRIAAmount = _layerToTRIAAmount(layer) ?? 0;
  return shareOfPremium + TRIAAmount;
};
