export const IS_DEV = process.env.NODE_ENV === "development";

const ROOT_STEM = process.env.REACT_APP_ROOT_STEM;

const ADMIN_STEM = process.env.REACT_APP_ADMIN_STEM;
const ANALYTICS_STEM = process.env.REACT_APP_ANALYTICS_STEM;
const ATTACHMENT_STEM = process.env.REACT_APP_ATTACHMENT_STEM;
const BFF_STEM = process.env.REACT_APP_BFF_STEM;
const CLAIMS_STEM = process.env.REACT_APP_API_STEM;
const DOC_GEN_STEM = process.env.REACT_APP_DOC_GEN_STEM;
const EMAIL_STEM = process.env.REACT_APP_EMAIL_STEM;
const EXCHANGE_STEM = process.env.REACT_APP_EXCHANGE_STEM;
const EXCHANGE_PROXY_STEM = process.env.REACT_APP_EXCHANGE_PROXY_STEM;
const FILES_STEM = process.env.REACT_APP_FILES_STEM;
const INGEST_STEM = process.env.REACT_APP_INGEST_STEM;
const MAPPING_SUGGESTIONS_STEM = process.env.REACT_APP_MAPPING_SUGGESTIONS_STEM;
const NOTES_STEM = process.env.REACT_APP_NOTES_STEM;
const PERSISTENCE_STEM = process.env.REACT_APP_PERSISTENCE_STEM;
const REPORTS_V2_STEM = process.env.REACT_APP_REPORTS_V2_STEM;
const SOV_STEM = process.env.REACT_APP_SOV_STEM;
const STATIC_DATA_STEM = process.env.REACT_APP_STATIC_DATA_STEM;
const TABLES_STEM = process.env.REACT_APP_TABLES_STEM;
const TRANSFER_STEM = process.env.REACT_APP_TRANSFER_STEM;
const USER_STEM = process.env.REACT_APP_USER_STEM;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "dev";

const sanitizePath = (path) => {
  if (path == null || typeof path !== "string") return "";
  return path.startsWith("/") ? path.substring(1) : path;
};

const urlAppendingPath = (baseUrl, path) => {
  if (!baseUrl && process.env.NODE_ENV !== "test") {
    throw new Error("Must specify a URL stem.");
  }
  return baseUrl + sanitizePath(path);
};

export const endpoints = {
  root: (path) => urlAppendingPath(ROOT_STEM, path),
  admin: (path) =>
    urlAppendingPath(ADMIN_STEM || endpoints.root("admin/"), path),
  analytics: (path) =>
    urlAppendingPath(ANALYTICS_STEM || endpoints.root("analytics/"), path),
  attachment: (path) =>
    urlAppendingPath(ATTACHMENT_STEM || endpoints.root("attachment/"), path),
  bff: (path) => urlAppendingPath(BFF_STEM || endpoints.root("bff/"), path),
  claims: (path) =>
    urlAppendingPath(CLAIMS_STEM || endpoints.root("claims/"), path),
  docGen: (path) =>
    urlAppendingPath(DOC_GEN_STEM || endpoints.root("doc-gen/"), path),
  email: (path) =>
    urlAppendingPath(EMAIL_STEM || endpoints.root("email/"), path),
  exchange: (path) =>
    urlAppendingPath(EXCHANGE_STEM || endpoints.root("exchange/"), path),
  exchangeProxy: (path) =>
    urlAppendingPath(
      EXCHANGE_PROXY_STEM || endpoints.root("exchange-proxy/"),
      path
    ),
  files: (path) =>
    urlAppendingPath(FILES_STEM || endpoints.root("files/"), path),
  ingest: (path) =>
    urlAppendingPath(INGEST_STEM || endpoints.root("ingest/"), path),
  mappingSuggestions: (path) =>
    urlAppendingPath(
      MAPPING_SUGGESTIONS_STEM || endpoints.root("suggest-column-mappings/"),
      path
    ),
  notes: (path) =>
    urlAppendingPath(NOTES_STEM || endpoints.root("notes/"), path),
  persistence: (path) =>
    urlAppendingPath(PERSISTENCE_STEM || endpoints.root("submissions/"), path),
  reports: (path) =>
    urlAppendingPath(REPORTS_V2_STEM || endpoints.root("reports-v2/"), path),
  sov: (path) => urlAppendingPath(SOV_STEM || endpoints.root("sov/"), path),
  staticData: (path) =>
    urlAppendingPath(STATIC_DATA_STEM || endpoints.root("static-data/"), path),
  tables: (path) =>
    urlAppendingPath(TABLES_STEM || endpoints.root("tables/"), path),
  transfer: (path) =>
    urlAppendingPath(TRANSFER_STEM || endpoints.root("transfer/"), path),
  user: (path) => urlAppendingPath(USER_STEM || endpoints.root("user/"), path),
};

const OPEN_REPLAY_PROJECT_ID = process.env.REACT_APP_OPEN_REPLAY_PROJECT_ID;
const OPEN_REPLAY_DISABLE_SECURE_MODE =
  process.env.REACT_APP_OPEN_REPLAY_DISABLE_SECURE_MODE || false;

export const getOpenReplayProjectId = () => OPEN_REPLAY_PROJECT_ID;
export const getOpenReplayDisableSecureMode = () =>
  OPEN_REPLAY_DISABLE_SECURE_MODE;

export const HEAP_APP_ID = process.env.REACT_APP_HEAP_APP_ID;

const MAINTENANCE_CONTROL_URL = process.env.REACT_APP_MAINTENANCE_CONTROL_URL;
const TOKEN_PRE_EXPIRY_REFRESH_SECONDS =
  process.env.REACT_APP_TOKEN_PRE_EXPIRY_REFRESH_SECONDS;

export const PURE_BURN_COST_KEY = "PURE_BURN_COST";
export const ILF_FROM_BASE_KEY = "SCALED_BASE_LAYER";
export const PURE_MODEL = "PURE_MODEL";
export const SCALED_EXPERIENCE = "SCALED_EXPERIENCE";
export const RECOMMENDED_BLEND = "RECOMMENDED_BLEND";
export const UW_ADJUSTED = "UW_ADJUSTED";

export const LOSS_TYPE_KEY = "_COVERAGE";
export const OPEN_CLOSED = "_OPEN_CLOSED";
export const EXCLUDE_KEY = "_EXCLUDE";

export const CURRENT_FILE_SCHEMA_VERSION = 29;

export const NAICS_SEEKER = {
  endpointStem: endpoints.claims(),
  endpoint: "pricing/naics/query",
  key: "PRICING_NAICS",
};

export const CATEGORY_CODE_SEEKER = {
  endpointStem: endpoints.claims(),
  endpoint: "pricing/classifications/query",
  key: "CODE_CATEGORY",
};

export const COMPANY_NAICS_SEEKER = {
  endpointStem: endpoints.claims(),
  endpoint: "pricing/naics/query",
  key: "COMPANY_NAICS",
};

export const PEER_NAICS_SEEKER = {
  endpointStem: endpoints.claims(),
  endpoint: "pricing/naics/query",
  key: "PEER_NAICS",
};

export const INDUSTRY_CLASS_SEEKER = {
  endpointStem: endpoints.claims(),
  endpoint: "exposure/industry-classes",
  key: "EXPOSURE_INDUSTRY_CLASSES",
};

export const PEER_SEEKER = {
  endpointStem: endpoints.persistence(),
  endpoint: "query",
  key: "PEER",
};

export const PRIOR_SUBMISSION = {
  endpointStem: endpoints.persistence(),
  endpoint: "query",
  key: "PRIOR_SUBMISSION",
};

export const DEFINITION_GNULR =
  "Expected Loss Cost / 100% Premium after Brokerage and Other Acquisition Costs are deducted.";
export const DEFINITION_TP =
  "Gross Premium less required Profit and Expenses / Expected Loss Cost with a loading for Brokerage and Other Acquisition costs.";
export const DEFINITION_TAC =
  "The Total Acquisition Costs: Sum of Brokerage and Other Acquisition Costs";
export const DEFINITION_IN_FORCE =
  "The date chosen such that all policies with an inception before it and an expiration after it will be selected.";

export const BUDGET_ACQUISITION_RATIO = 0.075;
export const BUDGET_GROSS_LOSS_RATIO = 0.68;
export const BUDGET_TECHNICAL_RATIO = 0.755;
export const PERMISSABLE_TECHNICAL_RATIO = 0.918994;
export const TARGET_TECHNICAL_RATIO = 0.858534;

export const TURNOVERS = [
  {
    key: "A",
    value: "Small - Below $5b",
  },
  {
    key: "B",
    value: "Medium - Between $5b and $10b",
  },
  {
    key: "C",
    value: "Large - Between $10b and $20b",
  },
  {
    key: "D",
    value: "Extra Large - Between $20b and $30b",
  },
  {
    key: "E",
    value: "Extra Extra Large - Over $30b",
  },
];

export const SCENARIOS = [
  {
    name: "Legacy Scenarios",
    items: [
      {
        id: "PPI",
        label: "Financial Product Mis-selling UK",
        tip:
          "This event arises from the systemic mis-selling by banks of a financial product sold within the UK. Although this event does not reflect a single historical or emerging event, the event is similar to Ponzi schemes such as the global Madoff and Stanford schemes, PPI mis-selling in the UK and a possible pension mis-selling event in the UK.",
      },
      {
        id: "INSIDER_TRADING",
        label: "Financial Market Manipulation US",
        tip:
          "This event arises from systemic misrepresentations in prospectuses or market / rate manipulations or other systemic unlawful or misleading banking practices in the US. Although this event does not reflect a single historical event, the event is similar to the IPO laddering market manipulation in the US during the late 1990’s, and the global Forex and LIBOR rigging events.",
      },
      {
        id: "OPIOD",
        label: "Pharmaceutical Product US",
        tip:
          "This event arises from a systemic failure by multiple pharmaceutical manufacturers to warn consumers of a significant harm from a generic drug or class of prescription drugs widely used for many years in the US. Although this event does not reflect a single historical or emerging event, the event is similar to the opioid crisis in the US, or a potential event from a generic drug such as paracetamol.",
      },
      {
        id: "CONSTRUCTION",
        label: "Construction Product Global",
        tip:
          "This is a global event arising from a latent design defect related to widely used construction products which results in the repair or rebuild of a few thousand residential structures. Although this event does not reflect a single historical or emerging event, the event is similar to the global issue with potentially flammable siding (cladding) such as Aluminium Composite Materials (ACM) and High-Pressure Laminates (HPL), or a possible issue such as mass timber.",
      },
      {
        id: "POISON",
        label: "Chemicals in Food US",
        tip:
          "This event arises from an agrochemical or other additive / dye used for decades in the US which is widely present in food and proven to cause significant bodily harm. Although this event does not reflect a single historical or emerging event, the event is similar to events involving potentially harmful agrochemicals such as DDT or glyphosates.",
      },
      {
        id: "ENERGY",
        label: "Offshore / Onshore Energy US",
        tip:
          "This event arises from a fire and subsequent boil-over at a US onshore fuel storage facility which provides a vital source of crude oil into a large proportion of the UW' refining capabilities. Although this is a synthetic event and does not reflect any historical event, previous fire and boil-overs include the 2019 Storage Tank Fire at Intercontinental Terminals in Texas and 1983 Milford Haven event in Wales.",
      },
    ],
  },
];

export const POLICY_FORMS = [
  {
    code: "AEGIS",
    name: "AEGIS",
  },
  {
    code: "BSF_CM",
    name: "BSF CM",
  },
  {
    code: "BSF_OCC",
    name: "BSF Occ",
  },
  {
    code: "CLAIMS_MADE",
    name: "Claims Made",
  },
  {
    code: "MXS_CM",
    name: "MXS CM",
  },
  {
    code: "MXS_OCC",
    name: "MXS Occ",
  },
  {
    code: "OCC01",
    name: "OCC01",
  },
  {
    code: "XS02",
    name: "XS02",
  },
  {
    code: "XS02_RI",
    name: "XS02 RI",
  },
  {
    code: "XS004",
    name: "XS004",
  },
  {
    code: "XS004_RI",
    name: "XS004 RI",
  },
  {
    code: "XS005",
    name: "XS005",
  },
  {
    code: "XS005_RI",
    name: "XS005 RI",
  },
  {
    code: "FAC_RI",
    name: "Fac RI",
  },
];

export const GRID_SPACING = 2;
export const SELECTED_ROW_BACKGROUND_COLOR = "#dbdce5";
export const FETCHING_OPACITY = 0.5;

export const MAINTENANCE_STATE_POLL_INTERVAL_SECS = 60;
export const MAINTENANCE_STATE_ERROR_RETRY_TIME_SECS = 5;
export const getMaintenanceControlUrl = () => MAINTENANCE_CONTROL_URL;

export const getTokenPreExpiryRefreshSeconds = () =>
  parseInt(TOKEN_PRE_EXPIRY_REFRESH_SECONDS || "900");

export const SUPPORT_EMAIL = "support@cactus.bm";

export const CLAIMS_MAPPING_IGNORE_VALUE = "[IGNORE]";
export const CLAIMS_MAPPING_INCLUDE_VALUE = "[INCLUDE]";

const readAwsExports = () => {
  try {
    return require("./aws-exports.js").default;
  } catch {
    return {};
  }
};

const awsExports = readAwsExports();

export const COGNITO_USER_POOL_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_ID ?? awsExports.aws_user_pools_id;
export const COGNITO_USER_POOL_CLIENT_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID ??
  awsExports.aws_user_pools_web_client_id;

export const DISABLE_COGNITO_HOSTED_UI =
  process.env.REACT_APP_DISABLE_COGNITO_HOSTED_UI === "true";
