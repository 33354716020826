import { Button } from "@mui/material";
import React from "react";

const ClaimsV1LossRunButton = ({ support }) => {
  if (support.canProcessLossFile()) {
    return (
      <Button
        variant={"contained"}
        size={"small"}
        disableElevation
        onClick={(e) => {
          support.processLossFile();
          e.stopPropagation();
        }}
      >
        {"Loss Run"}
      </Button>
    );
  } else {
    return <></>;
  }
};

export default ClaimsV1LossRunButton;
