import * as staticDataService from "services/staticDataService";
import { useLocalStorageJsonState } from "store/hooks/stateHooks";

const FeatureFlagged = ({
  flag,
  on,
  off,
  // This is to force the feature to be off. The intent is that this allows code to be
  // released behind a feature flag that even if the code is known not to work. So we
  // can start working on the integration without the risk that the feature flag is
  // accidentally enabled by someone who didn't know it wasn't ready.
  forceOff,
  ...props
}) => {
  const {
    data: featureFlags,
    isLoading,
  } = staticDataService.useFeatureFlagsQuery();

  const [optOut] = useLocalStorageJsonState("betaFeaturesOptOut", {
    default: {},
  });

  if (isLoading) {
    return <></>;
  }

  const Child = !!featureFlags?.[flag] && !forceOff && !optOut[flag] ? on : off;

  return <Child {...props} />;
};

export default FeatureFlagged;
